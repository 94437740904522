import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Banner, Tiles, Container, List } from '../blocks';
import Avatar from '../avatar';
export const wide = true;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  wide,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Banner mdxType="Banner">
      <h1>{`The Oracle of Binarybodi`}</h1>
      <p>{`Onchain Meta Magazine using NFTs.`}<br />{`
Featuring Perriniel philosphy, Spirituality, Meta Culture, Psych0-Analysis and Memes `}<br />{` `}<br /></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://twitter.com/binarybodi"
          }}>{`Manifesto`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/binarybodi"
          }}>{`Roadmap`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/0xneox"
          }}>{`Reddit`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/0xneox"
          }}>{`Discord`}</a></li>
      </ul>
    </Banner>
    <h2>{`Experimenting`}</h2>
    <Tiles mdxType="Tiles">
      <ul>
        <li parentName="ul"><h2 parentName="li">{`Dependencies of an NFT`}</h2>{` I'm in crypto since Early 2015, Prototyped and collaborated with many early-stage blockchain and cryptocurrencies. My feed includes Market TA, Dev and feature rollouts, market insights, and thoughts.`}</li>
        <li parentName="ul"><h2 parentName="li">{`Interlinking NFTs`}</h2>{` Experimenting through various paths and methods, I have been living a techno Spiritual life for 7 years. Constantly learning and
experiencing reality without getting attached.`}</li>
        <li parentName="ul"><h2 parentName="li">{`Standalone Framework`}</h2>{` My love for Entheogens is immaculate, I love experimenting with altered states of consciousness. I'm passionate about the extraction of natural psychedelics and their experiences. `}</li>
        <li parentName="ul"><h2 parentName="li">{`Embeddedness of Digital Assets`}</h2>{` I believe every human is equally made with the divine spirit, everyone has the potential to become the master. I explore the de-personification and Ego diffusing aspects of life and its benefits.`}</li>
      </ul>
    </Tiles>
    <p>{`We create an Oracle for bridging and linking all existing digital assets. We map all NFTs, ENS, and other virtual assets in a standalone platform. We create, curate, and collect the Context for The Oracle of Binary Bodi. The Oracle of Binary Bodi is the genesis edition by Metaverse Press Syndicate. Only Syndicate Members can contribute or submit or License their assets. Membership will be granted by purchasing Syndicate Pass. Only Syndicate Pass holders can participate in the content syndication of the Oracle. Artists, Syndicate members, and Contributors get % of royalties. Communities can co-create different Sub-Oracles according to their personalized need. We propose MPS as a movement towards forming a SyndicateDAO with SBT governance.`}</p>
    <h2>{`Metaverse Press Syndicate`}</h2>
    <p>{`Metaverse Press Syndicate, or Metaverse Feature Syndicate, is DAO that oversea the news trends, market trends, and other media special writing and artwork, often written by reputed and talented journalists or eminent generative projects or well-known Meta artists that cannot be classified as spot coverage of the news
Every writer, photographer, and graphic artist, generative projects syndicate their materials. With strong resources, anyone can syndicate their coverage, including news, to papers outside their communities. The use of many illustrations, color magazine sections, and glaring headlines; its sensational articles on spirituality and pseudoscientific topics; its bellicosity in perennial philosophy; and its transcendental wisdom. Degen gathers, Shitposters, Gm cults, writes, and distributes news from around the metaverse to the world to newspapers, Posters, Features, Periodicals, Radio and television broadcasters, media agencies, community projects, and other users. MPS does not generally publish news itself but supplies news to its subscribers, who, by sharing costs, obtain services they could not otherwise afford. Featuring routine coverage of news about the market trends, TA, rug pulls, confessions, CT drama, horrors and satires, and the like.`}</p>
    <h2>{`Features`}</h2>
    <p>{`Can attach or symlink every digital asset. Onchain collaboration and co-creation. Censorship resistance Press syndicate Interconnected NFTs Making NFTs self-sustainable. Magazines, Stories, Cartoons, Market trends and Analysis, Degen trades and Rug pulls, Moon shots and Gem swaps, Romance and CT trauma, weed and gm cult, and of course everything will be NFTs.`}</p>
    <p>{`Artists can submit their creations according to the context. Contributors can provide Media submissions for any Context. Authors can publish relevant text media for the Context Collaborators feel unity when every collaboration happens over Onchain.`}</p>
    <p>{`Every stakeholder is required to become a syndicate member to participate in the Oracle and MPS. Syndicate members can suggest submissions from various participants. The Oracle of Binary Bodi will be completely funded by MPS treasury which overseas syndicate pass liquidity. 90% of the Syndicate Pass sales volume will be diverted to the MPS Treasury Wallet. Facilitating the purchases of assets for Oracle and maintaining the royalty and loyalty rewards for all stakeholders.`}</p>
    <List sx={{
      py: 5,
      fontSize: 0
    }} mdxType="List">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://0xneo.xyz/blog/Bitcoin/"
          }}>{`Bitcoin`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://0xneo.xyz/blog/Energy/"
          }}>{`Energy`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://0xneo.xyz/blog/DMT/"
          }}>{`DMT`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://0xneo.xyz/blog/TransHuman/"
          }}>{`Transhumanism`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://0xneo.xyz/blog/NFT/"
          }}>{`NFT`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://0xneo.xyz/blog/Shivashakti/"
          }}>{`Shiva Shakti`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://basscss.com"
          }}>{`5-MEO-DMT`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/reline"
          }}>{`ENS`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/paths"
          }}>{`Ramana Maharishi`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/loading"
          }}>{`My Pharmacopeia`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://localhost:9000/blog/Soul/"
          }}>{`Transmigration of Soul`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://microbeats.cc"
          }}>{`Ethereum`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://mrsjxn.com"
          }}>{`Maya and Leela`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`MDMA`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Bitcoin Mining`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Divinity of Shakti`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Numbers`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Matrix`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Advaita`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Magic`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Meaning of Life`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Bitcoin and Billgates`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Kali`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Duality and Non Duality`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Shiva`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Ganga`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Rishikesh`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Kedarnath`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Rameshwaram`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`ISHA`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Art of Living`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Kundalini`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Satori`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Tantric Traditions`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Nihilism`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Galaxies`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Mountains`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Thou Art That`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Ego Death`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Ayahausca `}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Crystals`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Cone shells`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Patterns`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Tiruvannamalai`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Chakras`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Sleep and Death`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`How to Awaken?`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Purpose of Life?`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Secret of Life?`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`I Think`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Zodiacs`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Healing`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Matter & Form`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Sat Chit Ananda`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Why we Suffer?`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Atma & Paramatma`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Void`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Liberation`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Intution`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Clairevoynace`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Relams of Reality`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Samadhi`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://jxnblk.github.io/skullcat"
          }}>{`Oneness`}</a></li>
      </ul>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      